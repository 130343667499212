import React, {  useEffect, useState } from 'react';
import 'fontsource-roboto'; 
import Dashboard from './dashboard/Dashboard';
import SignIn from './sign-in/SignIn';
import {BASE_URL} from './config';
import axios from "axios";

import 'react-toastify/dist/ReactToastify.css';
import ChangePassword from './sign-in/ChangePassword';


import {loadRefData} from './api/ReferenceDataApi'
import RefdataContext from './context/RefdataContext';

function ScreenPicker(){
  const [session, setSession] = useState({});
  const [countyList, setCountyList] = useState([]);
  const [tripStatusList, setTripStatusList] = useState([]);
  const [driverList, setDriverList]= useState([]);
  const [vehicleList, setVehicleList]= useState([]);
  const [providerDriverList, setProviderDriverList]= useState([]);



  useEffect(()=>{
      let user = {'active':false,name:''}; 
      if(localStorage.getItem('session')){
      user = JSON.parse(localStorage.getItem('session'));
      }
      setSessionState(user);
    },[])
  

  async function setSessionState (user){
    if(! user.active ){
      try{
        const response = await axios.post(
          BASE_URL + "/user/logout",
          user
        );
        setSession({session : {'active':false,name:''}});
        localStorage.setItem('session', JSON.stringify({'active':false,name:''}) )
      }catch(error){ 
        console.log(error)
      }
      }else{
        setSession(user);
      }
      const data = await loadRefData(user);
      setCountyList(await data.countyList.rows)
      setTripStatusList(await data.tripStatusList.rows)
      setDriverList(await data.drivers.data);
      setVehicleList(await data.cars);
      setProviderDriverList(await data.providerDriverList);
  }  

  return (
    (session && session.active ) ? 
       session.pwdreset? <ChangePassword onLogin={setSessionState} />  
      : 
      <RefdataContext.Provider 
        value ={{
          countyList,
          tripStatusList,
          driverList,
          vehicleList,
          providerDriverList,
        }}>
          <Dashboard setSessionState={setSessionState } />
      </RefdataContext.Provider> 
    : 
    <SignIn onLogin={setSessionState} />  
  )
}

export default ScreenPicker;