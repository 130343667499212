import React, { useCallback, useEffect, useState } from 'react';
import {  Button, 
          Dialog, 
          DialogActions, 
          DialogContent, 
          DialogTitle, 
          Grid, 
          Table,
          TableBody,
          TableCell,
          TableContainer,
          TableHead,
          TableRow, Box} from '@mui/material';
import { attestationList } from "../../../Attestations/Attestations";
import { ToastContainer, toast } from "react-toastify";
import { styled } from '@mui/material/styles';

const StatusDiv = styled(Box)(({ theme, status }) => ({
  width: '30px !important',
  height: '30px !important' ,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  fontSize: 16,
  fontWeight: 'bold',
  userSelect: 'none',
  backgroundColor: status === 'success' ? theme.palette.success.main : '#ffffff',
}));

function AttestInvoiceStatusDialog ({
    showAttestionStatus,
    setShowAttestationStatus,
    attestationResponse,
    setAttestationEnvironment,
    setAttest,
    setSendBreadcrumbs,
    setAttestationData,
    attestationData,
    setReload,
}) {
    const [currentAttestationData, setCurrentAttestationData] = useState(attestationData);
    const [responseData, setResponsetData] = useState({});
    const [lastResponseStatus, setLastResponseStatus] = useState('success')
    
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(async () => {
      if (currentIndex < currentAttestationData?.invoiceNumbers?.length && (lastResponseStatus == 'success' || lastResponseStatus == 'retry')) {

        try{
          currentAttestationData.invoiceNumbers[currentIndex].status = 'Pending'
          const response = await attestationList.attestInvoice('Prod',currentAttestationData.type, 
              [attestationData.invoiceNumbers[currentIndex]], 
              currentAttestationData.tripDate, 
              currentAttestationData.drivers);

          if(response.status == 200) {
            currentAttestationData.invoiceNumbers[currentIndex].response = response.data[0]
            currentAttestationData.invoiceNumbers[currentIndex].timtDelta = new Date().getTime()
            currentAttestationData.invoiceNumbers[currentIndex].status = 'success'
            setCurrentIndex(currentIndex + 1);
          }
        }catch (error){
          console.log(error)
          setLastResponseStatus('failed')
          let newData = {...currentAttestationData}
          newData.invoiceNumbers[currentIndex].status = 'Failed'
          setCurrentAttestationData(newData)          

          toast.error(`[Error] ${error.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

    }, [currentAttestationData, currentIndex, lastResponseStatus])

    const handleRetry = useCallback(() => {
      let newData = {...currentAttestationData}
      newData.invoiceNumbers[currentIndex].status = 'Retrying'
      setCurrentAttestationData(newData)
      setLastResponseStatus('retry')
    }, [currentAttestationData]) 

    // useEffect(()=>{
    //   function updateResponseData(){
    //     let _responseData = responseData.map((inv)=>{
    //       if(inv.invoice.invoiceNumber == attestationResponse.response[0].invoice.invoiceNumber &&
    //       inv.invoice.triplegId == attestationResponse.response[0].invoice.triplegId)
    //       {
    //         inv.updateState = attestationResponse.response[0].updateState
    //         inv.breadcrumbs = attestationResponse.response[0].breadcrumbs
    //       }
    //       return inv;
    //     })
    //     setResponsetData(_responseData)
    //   }
    //   if(responseData.length > 0)
    //     updateResponseData();
    //   else 
    //     setResponsetData(attestationResponse.response)
    // },[`${attestationResponse.timtDelta}`])

    function handleCloseDialog(){
      setAttestationData({})
      setAttestationEnvironment('Prod');  // I will remove this once testing is completed and we are confident.
      setReload(true);
      setShowAttestationStatus(false);
    } 

    function handleProductionAttest(invoiceNumber, tripLegId) { // #Clarify
      let atd = currentAttestationData.invoiceNumbers.filter((_atd) => {
        return(_atd.invoiceNumber == invoiceNumber && _atd.triplegId == tripLegId)
      })

      setAttestationData({
        ...attestationData,
        invoiceNumbers:atd
      })
      setAttestationEnvironment('Prod');
      setAttest(true);
    }

    function handleSendBreadcrumbs(invoiceNumber, tripLegId) {
      let atd = currentAttestationData.invoiceNumbers.filter((_atd) => {
        return(_atd.invoiceNumber == invoiceNumber && _atd.triplegId == tripLegId)
      })

      setAttestationData({
        ...attestationData,
        invoiceNumbers:atd
      })
      setSendBreadcrumbs(true);
    }

    return (
        <Dialog
              open={showAttestionStatus}
              onClose={() => handleCloseDialog(null)}
              maxWidth="md"
              fullWidth={false}
            >
              <DialogTitle> Attestation Status </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Invoice Number</TableCell>
                                    <TableCell align="center">Trip Leg Id</TableCell>
                                    <TableCell align="center">Test Request</TableCell>
                                    <TableCell align="center">Production Request</TableCell>
                                    <TableCell align="center">Invoice</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    currentAttestationData?.invoiceNumbers?.length > 0? currentAttestationData?.invoiceNumbers?.map((resp, index) => {
                                        return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{resp.invoiceNumber}</TableCell>
                                                    <TableCell align="center">{resp.triplegId}</TableCell>
                                                    {
                                                      resp?.response?.updateState[0]?.environment == 'Testing'? 
                                                        <TableCell align="center">{resp?.response?.updateState[0]?.status}</TableCell> 
                                                      : <TableCell align="center">{"N/A"}</TableCell> 
                                                    }
                                                    {
                                                        resp?.response?.updateState[1]?.environment == 'Production'? 
                                                        <TableCell align="center">
                                                          <div style={{
                                                            display:'flex',
                                                            flexDirection:'row',
                                                            alignContent:'space-between',
                                                            alignItems:'center',
                                                            
                                                          }}>
                                                            <div style={{
                                                              paddingLeft:3,
                                                              paddingRight:3,
                                                            }}>
                                                              {resp?.response?.updateState[1]?.status}
                                                            </div>
                                                          </div>
                                                        </TableCell> 
                                                      : <TableCell align="center">
                                                          <div style={{
                                                              paddingLeft:3,
                                                              paddingRight:3,
                                                              textDecoration: 'underline',
                                                            }}>
                                                            <Button  onClick={(e)=>{handleProductionAttest(resp?.response?.invoice.invoiceNumber, resp?.response?.invoice.triplegId)}}>{attestationData.type} </Button>
                                                          </div>
                                                        </TableCell> 
                                                    }

                                                    <TableCell align="center">
                                                      <div style={{
                                                            display:'flex',
                                                            flexDirection:'row',
                                                            alignContent:'space-between',
                                                            alignItems:'center',
                                                            
                                                          }}>
                                                            <div style={{
                                                              paddingLeft:3,
                                                              paddingRight:3,
                                                            }}>
                                                              {resp?.response?.updateState[1].breadcrumbs? resp?.response?.updateState[1]?.breadcrumbs?.status : "N/A"}
                                                            </div>
                                                            {/* { attestationData.type == "Invoice" || attestationData.type == "NoShow" ? 
                                                                <div style={{
                                                                  paddingLeft:3,
                                                                  paddingRight:3,
                                                                  textDecoration: 'underline',
                                                                }}>
                                                                  <Button  onClick={(e)=>{handleSendBreadcrumbs(resp.invoice.invoiceNumber, resp.invoice.triplegId)}}>Send </Button>
      
                                                                </div>
                                                              : null
                                                            } */}
                                                      </div>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                      {resp.status == 'Failed' ? <Button
                                                        variant="contained"
                                                        sx={{
                                                          backgroundColor: '#ef6c00',
                                                          '&:hover': {
                                                            backgroundColor: '#ef6c00',
                                                          }
                                                        }}
                                                        onClick={() => handleRetry()}
                                                      >
                                                        Retry
                                                      </Button> : <StatusDiv status={resp.status}>
                                                        {resp.status === 'success' ? '✓' : ''}
                                                      </StatusDiv>}
                                                      
                                                    </TableCell>
                                                </TableRow>
                                        )
                                    }):null
                                }
                            </TableBody>    
                        </Table>
                    </TableContainer>
                  </Grid>
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseDialog()} variant="contained">Close</Button>
              </DialogActions>
            </Dialog>
      )
}

export default AttestInvoiceStatusDialog;